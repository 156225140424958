import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonItemDivider, IonLabel, IonList, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import './Settings.css';
import React, {useState} from "react";
import {UserStore} from "../store/UserStore";
import {alarmOutline, helpCircleOutline, openOutline} from "ionicons/icons";
import PrivateEventModal from '../components/PrivateEvents/PrivateEventModal';
import {AppVersion} from "../components/Settings/AppVersion";
import {DeleteAccount} from "../components/Settings/DeleteAccount";
import {SignOut} from "../components/Settings/SignOut";


const Settings: React.FC = () => {
    //const signOutHook = UserStore.useState(s => s.signOutHook);

    let imageSize;
        //"large";

    const [showPrivateEventModal, setShowPrivateEventModal] = useState<boolean>(false);

    const user = UserStore.useState(s => s.user);


    const privateEventModalOnDidDismissFn = () => setShowPrivateEventModal(false);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonButton>
                            <IonImg src="/EP-logo-small.png"></IonImg>
                        </IonButton>
                    </IonButtons>
                    <IonTitle class="ion-text-center">
                        Settings
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="ion-padding">
                <IonLabel><h2>Signed In As <strong>{user?.firstName} {user?.lastName} </strong>({user?.email})</h2></IonLabel>
                <IonList>
                    <IonItemDivider>
                        <IonLabel>Account</IonLabel>
                    </IonItemDivider>
                    <IonItem button={true} onClick={() => setShowPrivateEventModal(true)}>
                        <IonIcon color="success" slot="start" icon={alarmOutline} size={imageSize}></IonIcon>
                        <IonLabel>Subscribe to Private Event</IonLabel>
                    </IonItem>
                    <SignOut></SignOut>
                    <DeleteAccount></DeleteAccount>
                    <IonItemDivider>
                        <IonLabel>Other</IonLabel>
                    </IonItemDivider>

                    <IonItem button={true} onClick={() => window.open("https://help.eventpulse.app/index.htm#t=USER_APPLICATION%2FThe_Event_Pulse_User_Application.htm")}>
                        <IonIcon icon={helpCircleOutline} slot="start" color="primary"/>
                        <IonLabel>Help</IonLabel>
                    </IonItem>
                    <IonItem button={true} onClick={() => window.open("https://www.eventpulse.app/legal-documents")}>
                        <IonIcon icon={openOutline} slot="start" color="success"  size={imageSize}/>
                        <IonLabel>Terms & Conditions</IonLabel>
                    </IonItem>
                </IonList>
                <IonLabel>
                    <AppVersion></AppVersion>
                </IonLabel>
            </IonContent>
            <PrivateEventModal showPrivateEventModal={showPrivateEventModal} onDidDismiss={privateEventModalOnDidDismissFn}/>
        </IonPage>
    );
};

export default Settings;
