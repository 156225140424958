import React, {useState} from "react";
import {IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonLabel, IonModal, IonRow, IonTitle, IonToolbar} from "@ionic/react";
import {chevronDownOutline, chevronUpOutline, close} from 'ionicons/icons';
import {EventUserListViewModel} from "../../viewModel/EventUserListViewModel";
import {EventStore} from "../../store/EventStore";
import {format, isSameDay, parseISO} from "date-fns";
import SubscriptionToggleButton from "./SubscriptionToggleButton";
import './EventDescriptionCard.scss';

interface EventDescriptionCardProps {
    event: EventUserListViewModel;
    orgId: number;
    subscribed: boolean;
}

const EventDescriptionCard: React.FC<EventDescriptionCardProps> = ({event}) => {
    const [isOpen, setIsOpen] = useState(!event.subscribed);
    const [showAttachments, setShowAttachments] = useState(false);
    const event1 = EventStore.useState((s) => s.events.find((o) => o.eventId === event?.eventId));

    const formatDateRange = (startDate: string, endDate: string) => {
        const start = parseISO(startDate);
        const end = parseISO(endDate);

        if (isSameDay(start, end)) {
            const date = format(start, "MMM d, yyyy");
            const startTime = format(start, "h:mm a");
            const endTime = format(end, "h:mm a");
            const startMeridiem = format(start, "a");
            const endMeridiem = format(end, "a");

            if (startMeridiem === endMeridiem) {
                return `${date}, ${format(start, "h:mm")} - ${format(end, "h:mm")}${endMeridiem}`;
            } else {
                return `${date}, ${startTime} - ${endTime}`;
            }
        } else {
            return `${format(start, "MMM d, yyyy, h:mm a")} - ${format(end, "MMM d, yyyy, h:mm a")}`;
        }
    };

    if (event1) {
        return (
            <>
                    <IonGrid className={"secondary-bg"} onClick={() => setIsOpen(!isOpen)}>
                        <IonRow>
                            <IonCol size="8"><h4>{event1.eventName}</h4></IonCol>
                            <IonCol size="4" className={"ion-text-end"}>
                                <IonButton fill={"clear"}>
                                    <IonLabel  color="tertiary">
                                        {isOpen ? "Less" : "More"}
                                    </IonLabel>
                                    <IonIcon
                                        slot={"end"}
                                        icon={isOpen ? chevronUpOutline : chevronDownOutline}
                                        color="tertiary"

                                    />
                                </IonButton>
                            </IonCol>
                            <IonCol>
                                <strong>
                                    Event No. {event1.eventNumber}
                                </strong>
                                <br/>
                                <small>
                                    {event1?.startDate && event1?.endDate ? formatDateRange(event1.startDate, event1.endDate) : ''}
                                </small>
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                {isOpen && (
                    <IonCard color="light">
                        <IonCardHeader>
                            <IonCardTitle>{event1?.description}</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            {event1?.email && <p><strong>Contact:</strong> {event1?.email}</p>}
                            <small>
                                {event.subscribed
                                    ? event.subscriptionType === "ALL"
                                        ? "You're subscribed to all pulse notifications for this event"
                                        : "You're subscribed to localized pulse notifications for this event"
                                    : "You aren't subscribed to this event"}
                            </small>

                        </IonCardContent>
                        {/*<IonButton*/}
                        {/*    fill="solid"*/}
                        {/*    // expand="block"*/}
                        {/*    color="tertiary"*/}
                        {/*    onClick={() => setShowAttachments(true)}*/}
                        {/*    style={{*/}
                        {/*    }}>*/}
                        {/*    <IonIcon icon={attachOutline} slot="start" />*/}
                        {/*    Attachments*/}
                        {/*</IonButton>*/}
                        <SubscriptionToggleButton
                            eventId={event1.eventId}
                            subscribed={event1.subscribed}
                            //subscribed={true}
                            subscriptionType={event1.subscriptionType}
                            eventNumber={event1.eventNumber}
                        />
                    </IonCard>
                )}

                {/*Attachments Modal*/}
                <IonModal isOpen={showAttachments} onDidDismiss={() => setShowAttachments(false)}>
                    <IonCard color="secondary">
                        <IonHeader>
                            <IonToolbar color="primary">
                                <IonTitle class="ion-text-center">
                                    Attachments
                                </IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <IonCardHeader>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}>
                                <IonCardTitle style={{fontSize: "1.5em"}}>Files from {event.eventName}</IonCardTitle>
                                <IonButton
                                    shape="round"
                                    fill="clear"
                                    mode="ios"
                                    color="light"
                                    style={{marginRight: "-15px"}}
                                    onClick={() => setShowAttachments(false)}
                                >
                                    <IonIcon icon={close} style={{fontSize: "28px"}}/>
                                </IonButton>
                            </div>
                        </IonCardHeader>
                        <IonCardContent>
                            Event No. {event1.eventNumber}
                            {/* COMPONENT WILL GO HERE */}
                            <p>No attachments available.</p>
                        </IonCardContent>
                    </IonCard>
                </IonModal>
            </>
        );
    }
    return null;
};

export default EventDescriptionCard;
