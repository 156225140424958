import React, {useEffect, useState} from 'react';
import {IonButton, IonIcon, IonItem, IonLabel, IonList, isPlatform, useIonViewWillEnter} from '@ionic/react';
import {chevronForward} from 'ionicons/icons';
import {EventUserListViewModel} from '../../viewModel/EventUserListViewModel';
import {EventStoreActions} from '../../store/EventStore';
import {PulseTrackerType} from '../../viewModel/MyEventsPulseTrackerView.model';
import eventEmitter from '../../utilities/EventEmitter';

const UserEventList: React.FC = () => {
    const [results, setResults] = useState<EventUserListViewModel[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>('');
    const [pulseTracker, setPulseTracker] = useState<PulseTrackerType | null>(null);
    const lastCheckedTimeRef = React.useRef<Date | null>(null);
    const intervalRef = React.useRef<NodeJS.Timeout | null>(null);

    const fetchNewPulses = async () => {
        try {
            const lastCheckedTime = lastCheckedTimeRef.current;
            if (lastCheckedTime) {
                const result = await EventStoreActions.findNewPulsesCountAction.run({ lastCheckedTime: lastCheckedTime.toISOString() });
                if (result.payload?.pulseTrackerResult) {
                    setPulseTracker(result.payload.pulseTrackerResult.pulseTracker);
                } else {
                    setPulseTracker({});
                }
                console.log(pulseTracker);
            }
        } catch (error) {
            console.error('Error fetching new pulses:', error);
        }
    };

    useEffect(() => {
        intervalRef.current = setInterval(fetchNewPulses, 15000); // 15 seconds

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    useIonViewWillEnter(() => {
        loadUserEvents();
        lastCheckedTimeRef.current = new Date(); // Set initial lastCheckedTime
    });

    const loadUserEvents = async () => {
        setError('');

        try {
            const res = await EventStoreActions.findAllEventsByUser.run();
            setResults(res.payload?.userSubscribedEvents || []);
        } catch (error) {
            console.error(error);
            setError('Error occurred while searching');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const trigger = () => loadUserEvents().then();
        eventEmitter.on('reload', trigger);
        return () => {
            eventEmitter.off('reload', trigger);
        }
    }, []);

    return (
        <>
            {loading && <p style={{ paddingLeft: '1.5em', paddingTop: '1em' }}>Loading your events...</p>}
            {error && <p style={{ paddingLeft: '1.5em', paddingTop: '1em' }}>{error}</p>}

            {!loading && !error && results.length === 0 && (
                <p style={{ paddingLeft: '1.5em', paddingRight:'1em', paddingTop: '1em' }}>
                    No event subscriptions! Search with the Discover tab to get started!
                </p>
            )}

            {!loading && !error && results.length > 0 && (
                <IonList>
                    {results.map((event) => {
                        const eventId = event.eventId;
                        const newPulseCount = pulseTracker?.[eventId] || 0;

                        return (
                            <IonItem
                                key={eventId}
                                routerLink={`/myevents/event/${eventId}`}
                                style={{paddingRight:"0.35em", paddingTop:"0.15em"}}
                            >
                                <IonLabel>
                                    <h2>{event.eventName}</h2>
                                    <p style={{color:'grey'}}>Event No.{event.eventNumber} &nbsp;- &nbsp;{event.pulseCount} pulse{event.pulseCount !== 1 ? 's' : ''}</p>
                                    <h3 style={{color:'grey'}}>{event.organizationName}</h3>
                                </IonLabel>
                                <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                                    {newPulseCount > 0 && (
                                        <span style={{ marginRight: '0.4em' }}>
                                            <IonButton color="tertiary">
                                                {newPulseCount} new pulses!
                                            </IonButton>
                                        </span>
                                    )}
                                    {!isPlatform('ios') && <IonIcon color="tertiary" icon={chevronForward} />}
                                </div>
                            </IonItem>
                        );
                    })}
                </IonList>
            )}
        </>
    );
};

export default UserEventList;
